<template>
    <div class="question-preview">
        <div class="question-preview-content">
            <RealPhonePreview>
                <template v-slot:preview>
                    <div class="question-page">
                        <template v-for="question in data.questions">
                            <template v-if="currentQuestion == question.order">
                                <div
                                    class="question-item-card"
                                    :key="question.order + '_view'"
                                >
                                    <div class="question-item-card-order">
                                        第{{ question.order }}/{{
                                            data.questions.length
                                        }}题
                                    </div>
                                    <div class="question-item-card-title">
                                        {{ question.order }}.{{
                                            question.question
                                        }}
                                    </div>
                                    <div class="question-item-card-content">
                                        <template
                                            v-if="
                                                question.type !=
                                                QUESTION_TYPE.FILL_BLANK
                                            "
                                        >
                                            <div
                                                v-for="(
                                                    option, index
                                                ) in question.options"
                                                class="question-item-card-content-option"
                                                :key="index"
                                            >
                                                {{ option }}
                                            </div>
                                        </template>
                                        <template v-else>
                                            <textarea
                                                maxlength="100"
                                                placeholder="限100字"
                                            ></textarea>
                                        </template>
                                    </div>
                                </div>
                                <div
                                    class="btn-group"
                                    :key="question.order + '_btn'"
                                >
                                    <button
                                        v-if="
                                            currentQuestion > 1 &&
                                            currentQuestion <=
                                                data.questions.length
                                        "
                                        @click="currentQuestion--"
                                    >
                                        上一题
                                    </button>
                                    <button
                                        v-if="
                                            currentQuestion ==
                                            data.questions.length
                                        "
                                    >
                                        提交
                                    </button>
                                    <button
                                        v-if="
                                            currentQuestion >= 1 &&
                                            currentQuestion <
                                                data.questions.length
                                        "
                                        @click="currentQuestion++"
                                    >
                                        下一题
                                    </button>
                                </div>
                            </template>
                        </template>
                    </div>
                </template>
            </RealPhonePreview>
        </div>
        <slot name="footer"> </slot>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";
import { QUESTION_TYPE } from "@/constant";

export default {
    name: "questionnairePreview",
    components: {
        RealPhonePreview,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            QUESTION_TYPE,
            currentQuestion: 1,
        };
    },
};
</script>

<style lang="stylus" scoped>
.question-preview
    padding 0 30px
    display flex
    flex-flow column nowrap
    align-items center
    &-content
        padding 30px
.step-footer
    border-top 1px solid #f0f0f0
    padding 30px 0
    text-align center
    width 100%
    button
        width 200px
        height 40px
        background var(--pc-theme-color)
        color #fff
        font-size 24px
        border none
        outline none
        box-shadow 0 5px 10px 0 rgba(0, 182, 148, 0.3)
        border-radius 4px
.question-page
    background var(--bg-color)
    height 100%
    padding calc((30px / 2.5)) calc((34px / 2.5))
    display flex
    flex-flow column nowrap
    align-items center
    overflow-y auto
    .question-item-card
        position relative
        width calc((682px / 2.5))
        background #FFFFFF
        box-shadow 0px 2px 4px 0px rgba(0, 0, 0, 0.1)
        border-radius 4px
        padding calc((30px / 2.5))
        padding-top calc((80px / 2.5))
        &-order
            position absolute
            left 0
            top 8px
            width calc((130px / 2.5))
            height 16px
            line-height 16px
            background #FFE6E6
            box-shadow 1px 0 calc((4px / 2.5)) 0 rgba(254, 189, 189, 0.3)
            border-radius 0 2px 2px 0
            font-size calc((26px / 2.5))
            color var(--theme-color)
            text-align center
        &-title
            font-size calc((30px / 2.5))
            margin-bottom calc((30px / 2.5))
        &-content
            &-option
                width calc((620px / 2.5))
                height calc((60px / 2.5))
                border-radius 4px
                border 1px solid #C6C6C6
                padding 0 8px
                line-height calc((60px / 2.5))
                cursor pointer
                &.active
                    border-color var(--theme-color)
                    color var(--theme-color)
                &:not(:last-of-type)
                    margin-bottom calc((30px / 2.5))
            textarea
                width 100%
                height calc((200px / 2.5))
                background #F0F0F0
                border-radius 4px
                resize none
                border none
                outline none
    .btn-group
        margin-top 32px
        display flex
        flex-flow row nowrap
        justify-content space-around
        button
            max-width calc((480px / 2.5))
            min-wdith calc((310px / 2.5))
            height calc((82px / 2.5))
            background #FF5A5A
            font-size calc((36px / 2.5))
            box-shadow 0px 4px 8px 0px rgba(255, 90, 90, 0.3)
            border-radius 4px
            border none
            flex 1 0 auto
            outline none
            color #fff
            &:not(:last-of-type)
                margin-right 12px
</style>