var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"question-preview"},[_c('div',{staticClass:"question-preview-content"},[_c('RealPhonePreview',{scopedSlots:_vm._u([{key:"preview",fn:function(){return [_c('div',{staticClass:"question-page"},[_vm._l((_vm.data.questions),function(question){return [(_vm.currentQuestion == question.order)?[_c('div',{key:question.order + '_view',staticClass:"question-item-card"},[_c('div',{staticClass:"question-item-card-order"},[_vm._v(" 第"+_vm._s(question.order)+"/"+_vm._s(_vm.data.questions.length)+"题 ")]),_c('div',{staticClass:"question-item-card-title"},[_vm._v(" "+_vm._s(question.order)+"."+_vm._s(question.question)+" ")]),_c('div',{staticClass:"question-item-card-content"},[(
                                            question.type !=
                                            _vm.QUESTION_TYPE.FILL_BLANK
                                        )?_vm._l((question.options),function(option,index){return _c('div',{key:index,staticClass:"question-item-card-content-option"},[_vm._v(" "+_vm._s(option)+" ")])}):[_c('textarea',{attrs:{"maxlength":"100","placeholder":"限100字"}})]],2)]),_c('div',{key:question.order + '_btn',staticClass:"btn-group"},[(
                                        _vm.currentQuestion > 1 &&
                                        _vm.currentQuestion <=
                                            _vm.data.questions.length
                                    )?_c('button',{on:{"click":function($event){_vm.currentQuestion--}}},[_vm._v(" 上一题 ")]):_vm._e(),(
                                        _vm.currentQuestion ==
                                        _vm.data.questions.length
                                    )?_c('button',[_vm._v(" 提交 ")]):_vm._e(),(
                                        _vm.currentQuestion >= 1 &&
                                        _vm.currentQuestion <
                                            _vm.data.questions.length
                                    )?_c('button',{on:{"click":function($event){_vm.currentQuestion++}}},[_vm._v(" 下一题 ")]):_vm._e()])]:_vm._e()]})],2)]},proxy:true}])})],1),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }